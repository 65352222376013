<template>
  <div id="transaction-history">
    <div class="title">İşlem Geçmişi</div>

    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }"><i class="icon-home"></i></el-breadcrumb-item>
        <el-breadcrumb-item>Anasayfa</el-breadcrumb-item>
        <el-breadcrumb-item>İşlem Geçmişi</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="buttons-group">
        <el-tooltip class="item" effect="dark" content="Export" placement="top">
          <el-button class="export" @click="exportFile"><i class="icon-export"></i></el-button>
        </el-tooltip>
      </div>
    </div>
    <div class="table-general">
      <div class="search-status">
        <div class="status">
          <div class="show">Göster</div>
          <el-select class="first" v-model="filter.PageSize" placeholder="Seçiniz...">
            <el-option v-for="(item, index) in pagesize" :key="index" :label="item" :value="item"> </el-option>
          </el-select>
          <el-select v-model="filter.UserActions" placeholder="Seçiniz..." v-if="getEnums != null" clearable @clear="filter.UserActions = null">
            <el-option v-for="item in getEnums.find((x) => x.Title == 'UserActions').Enums" :key="item.Key" :label="item.DisplayName" :value="item.Key"> </el-option>
          </el-select>
          <el-select v-model="filter.HistoryStatus" placeholder="Seçiniz..." v-if="getEnums != null" clearable @clear="filter.HistoryStatus = null">
            <el-option v-for="item in getEnums.find((x) => x.Title == 'HistoryStatus').Enums" :key="item.Key" :label="item.DisplayName" :value="item.Key"> </el-option>
          </el-select>
        </div>

        <div class="search">
          <el-input style="width: 290px" placeholder="Arama..." v-model="filter.SearchTerm" clearable>
            <i slot="prefix" class="el-input__icon el-icon-search" style="color: #8697a8; font-weight: bold"></i>
          </el-input>
        </div>
      </div>
      <div class="all-data">
        <div class="list-table">
          <el-table :data="transactionList">
            <el-table-column label="DURUM" sortable width="200">
              <template slot-scope="scope">
                <div class="color-dot" v-if="scope.row.UserActions != null || scope.row.UserActions != undefined">
                  {{ getEnumsDisplay("UserActions", scope.row.UserActions) }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="İŞLEM" sortable width="200">
              <template slot-scope="scope">
                <div class="color-dot" v-if="scope.row.Status != null || scope.row.Status != undefined">
                  {{ getEnumsDisplay("HistoryStatus", scope.row.Status) }}
                </div>
              </template>
            </el-table-column>
            <el-table-column sortable prop="Note" label="AÇIKLAMA"></el-table-column>
            <el-table-column sortable label="TARİH" stripe width="230">
              <template slot-scope="scope">
                <div class="dates">
                  <div>
                    {{ $moment(scope.row.CreatedDateTime).format("DD.MM.YYYY, HH:mm:ss") }}
                  </div>
                </div>
              </template>
            </el-table-column>
            <template slot="empty">
              <div class="lottie-container">
                <v-lottie-player name="noData" loop path="/lottie/no-data.json" />
                <div class="no-data-text">İşlem geçmişi bulunamadı.</div>
                <div class="no-data-text">Herhangi bir işlem geçmişi bulunamamıştır.</div>
              </div>
            </template>
          </el-table>
        </div>
      </div>
      <div class="pagination">
        <div class="info">{{ filter.PageSize * filter.Page - filter.PageSize }}-{{ filter.PageSize * filter.Page > result.TotalCount ? result.TotalCount : filter.PageSize * filter.Page }} {{ $localization('web-pagination-info-text')
        }} {{ result.TotalCount }}</div>
        <el-pagination background :current-page.sync="filter.Page" :page-size="filter.PageSize" layout="prev, pager, next" :total="result.TotalCount"></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pagesize: [10, 20, 30, 40],
    };
  },
  async beforeMount() {
    await this.$store.dispatch("getTransactionList");
  },
  computed: {
    filter() {
      return this.$store.getters.getTransactionFilter;
    },
    transactionList() {
      return this.$store.getters.getTransactionList;
    },
    validation() {
      return this.$store.getters.getTransactionValidation;
    },
    result() {
      return this.$store.getters.getTransactionResult;
    },
    getEnums() {
      return this.$store.state.auth.enums;
    },
  },
  methods: {
    exportFile() {
      var label = "IslemGecmisi.xlsx";
      this.$client
        .get("/History/ExportReports", { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = label;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
    },
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        this.$store.dispatch("getTransactionList");
      },
    },
  },
};
</script>

<style lang="less">
#transaction-history {
  padding: 42px 30px;

  .title {
    font-size: 30px;
    color: #44566c;
    font-weight: 700;
    line-height: 35px;
    padding-bottom: 22px;

    span {
      color: #8697a8;
    }
  }

  .breadcrumb {
    .buttons-group {
      display: flex;
      gap: 24px;

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        background: #eaedf0;
        border: none;

        i {
          background: #44566c;
        }
      }
    }
  }

  .table-general {
    display: flex;
    flex-direction: column;

    .search-status {
      display: flex;
      justify-content: space-between;
      margin-bottom: 22px;

      .search {
        .el-input {
          width: 290px;
          border-radius: 5px;

          input {
            border: none;
            border-radius: 5px;
            background-color: white;
            color: #8697a8;
            font-size: 15px;
            font-weight: 400;
            line-height: 17px;
            box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.1);
          }
        }
      }

      .status {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 20px;

        .show {
          font-weight: 400;
          font-size: 15px;
          line-height: 18px;
          color: #44566c;
          margin-right: 10px;
        }

        .el-select {
          border-radius: 8px;
          max-width: 145px;

          &:first-child {
            width: 75px;
          }

          &:last-child {
            width: 145px;
          }

          border: none;

          .el-input {
            input {
              border-radius: 5px;
              border: none;
              color: #44566c;
              background-color: white;
              font-size: 15px;
              font-weight: 400;
              line-height: 17px;
              box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.1);
            }
          }
        }
      }
    }

    .pagination {
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .info {
        font-size: 15px;
        font-weight: 400;
        color: #8697a8;
      }

      .el-pagination {
        padding: 0;
        display: flex;
        flex-direction: row;
        align-items: center;

        box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.1);
        background-color: white;
        border-radius: 8px;

        button {
          background-color: white;
          padding: 0;
          margin: 0;
          height: 100% !important;

          &.btn-prev {
            border-radius: 8px 0 0 8px;
            border-right: 1px solid #eaedf0;
          }

          &.btn-next {
            border-radius: 0 8px 8px 0;

            border-left: 1px solid #eaedf0;
          }
        }

        .el-pager {
          margin: 0 10px;
          padding: 5px;
          display: flex;
          flex-direction: row;
          gap: 5px;
          justify-content: space-between;

          li {
            padding: 0;
            margin: 0;
            background-color: white;

            &.active {
              background-color: #234A98;
              border-radius: 8px;
              box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.05);
            }
          }
        }
      }
    }
  }

  .el-input__suffix {
    .el-input__suffix-inner {
      .el-select__caret {
        background-color: rgba(255, 255, 255, 0);
      }
    }
  }

  .color-dot {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;

    .dot {
      height: 5px;
      width: 5px;
      background-color: black; // default #ff3d57 danger #FDBF5E kritik #09B66D neew
      border-radius: 50%;
      display: inline-block;

      &.red {
        background-color: #ff3d57;
      }

      &.green {
        background-color: #09b66d;
      }

      &.yellow {
        background-color: #fdbf5e;
      }
    }
  }
}</style>
